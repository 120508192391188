/**
 * public-path.js
 *
 * This is an entry file (forked from EUA) that is used to set the `output.publicPath` option
 * in webpack on the fly based on the static host info injected by forms-web config.
 * https://webpack.js.org/guides/public-path/#set-value-on-the-fly
 */

if (
  window.Box &&
  window.Box.config &&
  window.Box.config.staticUrl &&
  window.Box.config.staticPath
) {
  // eslint-disable-next-line
  __webpack_public_path__ = `${window.Box.config.staticUrl}/${window.Box.config.staticPath}/`;
}
